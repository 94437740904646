<template>
    <div>
        <NavBar v-if="showNavBar"></NavBar>
        <div id="app">
            <router-view/>
        </div>
        <AppFooter></AppFooter>
    </div>
</template>

<style>
    @media only screen and (min-width: 1400px) {
        #app {
            min-width: 700px;
        }
    }
    #app {
        margin: 0 auto;
        min-height: 92vh;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    nav {
      padding: 30px;
    }

    nav a {
    /*  font-weight: bold;*/
      color: #2c3e50;
    }

    nav a.router-link-exact-active {
      color: #42b983;
    }
</style>

<script>
import NavBar from "@/components/NavBar";
import AppFooter from "@/components/AppFooter";

export default {
    components: {AppFooter, NavBar},
    data() {
        return {
            showNavBar: true
        }
    },
    watch: {
        $route: {
            handler: function($route) {
                this.showNavBar = $route.name === "home"
            },
            deep: true,
            immediate: true
        }
    }

}
</script>