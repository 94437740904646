<template>
    <div>
        <footer class="text-center">
            <nav style="padding: 5px">
                <ul style="list-style-type: none; padding: 0">
                    <li style="display: inline; margin: 0 5px 5px">
                        <router-link to="mentions-legales">Mentions légales</router-link>
                    </li>
                    <li style="display: inline; margin: 0 5px 5px">
                        <router-link to="nous-contacter">Nous contacter</router-link>
                    </li>
                </ul>
            </nav>
            <p>
                &copy;2022 DS SANITEK
            </p>
        </footer>
    </div>
</template>

<script>
export default {
    name: "AppFooter"
}
</script>

<style scoped>

</style>