<template>
    <div class="navbar-holder">
        <div class="navbar-holder d-none-sm">
            <nav class="nav-bar-navigator">
                <a href="#">Accueil</a>
                <a href="#emergency">Urgence</a>
                <img
                    style="width: 35px; height: 35px;"
                    src="@/assets/logo.png"
                />
                <a href="#tariffs">Forfait</a>
                <a href="#leak-search">Recherche de fuite</a>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavBar",
}
</script>

<style scoped>
    @media only screen and (min-width: 1024px) {
        .nav-bar-navigator {
            padding: 10px 100px;
        }
    }
    @media only screen and (max-width: 1024px) {
        .nav-bar-navigator {
            padding: 10px;
        }
    }
    .navbar-holder {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1;
        background-color: #4682B4;
    }
    .nav-bar-navigator {
        display: flex;
        justify-content: space-between;
    }

    .nav-bar-navigator a {
        color: white;
    }
    .nav-bar-navigator a:hover {
        color: #2c3e50;
    }
</style>