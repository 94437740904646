<template>
    <div class="about-us section-holder">
        <section>
            <div style="display: flex">
                <div class="d-none-sm">
                    <img style="width: 500px" src="@/assets/img1.jpg" alt="working-man"/>
                </div>
                <div>
                    <h1
                        class="text-center"
                        style="margin: auto;"
                    >
                        Qui sommes-nous ?
                    </h1>
                    <div class="margin-10-30">
                        <ul>
                            <li>
                                <p>
                                    Notre entreprise DS SANITEK, implantée en Île-de-France, a été créée en 2022 par Ducrot Sébastien, technicien plombier sanitaire et expert en recherche de fuite.
                                </p>
                            </li>
                            <li>
                                DS SANITEK vous fait profiter de son expérience et vous accompagne dans l'étude personnalisée de votre projet jusqu'à la réalisation.
                            </li>
                            <li>
                                Un professionnel à votre service pour améliorer votre confort: du petit dépannage à la rénovation de votre salle de bain ainsi que tous vos appareils sanitaires sans exception !
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="mt-20-sm" style="display: flex;">
                <div
                    class="text-center thin-border"
                    style="width: 600px; margin: auto;"
                >
                    <h3>3 bonnes raisons de solliciter</h3>
                    <h3 class="text-underline text-uppercase">Ds Sanitek</h3>
                    <ol
                        style="text-align: left;"
                    >
                        <li>Disponibilité et réactivité. Votre confort n'attend pas</li>
                        <li>
                            Professionalisme avec plus de <strong>8 ans</strong> d'expérience dans le domaine
                        </li>
                        <li>
                            Des tarifs transparents imbattables et des installations couvertes
                            par garantie
                        </li>
                    </ol>
                </div>
                <div class="d-none-sm">
                    <img style="width: 500px" src="@/assets/img2.jpeg" alt="working-man"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutUs"
}
</script>

<style scoped>
    .about-us {
        background-color: white; /*#DDE7DC*/
    }

    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin-top: 15px;
    }
    @media only screen and (min-width: 1400px) {
        .margin-10-30 {
            margin: 10px 30px;
        }
    }
</style>