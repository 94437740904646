<template>
    <div>
        <svg viewBox="0 0 1024 100">
            <rect x="0" y="0" width="1024" height="100" fill="#FFFFFF"></rect><path d="M0 55L34.2 53.7C68.3 52.3 136.7 49.7 205 53C273.3 56.3 341.7 65.7 409.8 71.2C478 76.7 546 78.3 614.2 71.3C682.3 64.3 750.7 48.7 819 42.8C887.3 37 955.7 41 989.8 43L1024 45L1024 101L989.8 101C955.7 101 887.3 101 819 101C750.7 101 682.3 101 614.2 101C546 101 478 101 409.8 101C341.7 101 273.3 101 205 101C136.7 101 68.3 101 34.2 101L0 101Z" fill="#1A3852" stroke-linecap="round" stroke-linejoin="miter"></path>
        </svg>
        <div class="app-emergency section-holder">
            <section>
                <h1
                    class="text-center"
                    style="width: 200px; margin: auto;"
                >
                    URGENCE
                </h1>
                <p>
                    Pour toute urgence de fuite privative, contactez directement notre
                    technicien par téléphone pour agir dans les plus brefs délais.
                </p>
                <p>
                    Pour être totalement transparent avec nos clients nous appliquons les
                    mêmes tarifs et forfaits que vous pouvez retrouver dans la section "Tarifs" ci-dessous.
                </p>
                <p>
                    Une majoration de 20% sera appliquée pour toute intervention comprise entre 18h et minuit et les week-ends.
                </p>
                <h1 class="text-center"><a href="tel:06-17-35-44-05">06 17 35 44 05</a></h1>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppEmergency"
}
</script>

<style scoped>
.app-emergency {
    color: white;
    background-color: #1a3852;/*#C1DEE2;*/
}

a {
    color: #ff931e;
}
</style>