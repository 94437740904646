<template>
    <div>
        <div class="logo-container">
            <img style="width: 100%;display: block" src="@/assets/Logo_banniere.png" alt="ds-sanitek-logo"/>
            <a href="tel:06-17-35-44-05" class="top-left d-none-xsm" style="font-size: 16px">06 17 35 44 05</a>
            <a class="top-right d-none-xsm" href="mailto:contact@ds-sanitek.fr">contact@ds-sanitek.fr</a>
        </div>

        <AboutUs ></AboutUs>

        <AppEmergency id="emergency"></AppEmergency>

        <PriceList id="tariffs"></PriceList>

        <LeakSearch id="leak-search"></LeakSearch>
    </div>
</template>

<style scoped>
    a {
        text-decoration: none;
        color: white;
    }
</style>

<script>
    import PriceList from "@/components/PriceList";
    import LeakSearch from "@/components/LeakSearch";
    import AppEmergency from "@/components/AppEmergency";
    import AboutUs from "@/components/AboutUs";
    export default {
        name: "HomeView",
        components: {LeakSearch, AboutUs, AppEmergency, PriceList},
    };
</script>
