<template>
    <div>
        <svg viewBox="0 0 1024 100">
            <rect x="0" y="0" width="1024" height="100" fill="#1A3852"></rect><path d="M0 59L17 56C34 53 68 47 102.2 39.8C136.3 32.7 170.7 24.3 204.8 20.5C239 16.7 273 17.3 307.2 22.2C341.3 27 375.7 36 409.8 42C444 48 478 51 512 48.5C546 46 580 38 614.2 33.8C648.3 29.7 682.7 29.3 716.8 28.8C751 28.3 785 27.7 819.2 32C853.3 36.3 887.7 45.7 921.8 43.8C956 42 990 29 1007 22.5L1024 16L1024 0L1007 0C990 0 956 0 921.8 0C887.7 0 853.3 0 819.2 0C785 0 751 0 716.8 0C682.7 0 648.3 0 614.2 0C580 0 546 0 512 0C478 0 444 0 409.8 0C375.7 0 341.3 0 307.2 0C273 0 239 0 204.8 0C170.7 0 136.3 0 102.2 0C68 0 34 0 17 0L0 0Z" fill="#FFF" stroke-linecap="round" stroke-linejoin="miter"></path>
        </svg>
        <div class="section-holder" style="color: white;background-color: #1a3852">
            <section style="/*font-size: 22px;*/">
                <div class="text-center">
                    <h1
                        style="margin: auto;"
                    >
                        RECHERCHE DE FUITE
                    </h1>
                    <div class="border-rounded width-400-xl width-300-sm" style="margin: 10px auto auto">
                        <p>
                            La recherche de fuite non destructive est bien plus complexe qu'elle
                            n'y parait et necessite plusieurs méthodes pour y arriver. Voici nos
                            différentes techniques
                        </p>
                    </div>
                </div>

                <div style="width: 70%; margin: 50px auto auto">
                    <div style="display: flex; flex-direction: column">
                        <div class="align-self-end-xl align-self-center-sm border-rounded width-400-xl width-300-sm">
                            <h3 class="text-underline">Arrosage à l'eau Fluorescéine</h3>
                            <p>
                                Un arrosage coloré reproduit artificiellement la pluie et permet
                                ainsi de localiser les défauts d'étanchéité
                            </p>
                        </div>

                        <div class="align-self-start-xl align-self-center-sm border-rounded width-400-xl width-300-sm mt-20-sm">
                            <h3 class="text-underline">Mise en pression par gaz traceur</h3>
                            <p>
                                Le gaz traceur est un mélange d'azote et d'hydrogène non inflamable
                                et non toxique. Il est principalement utilisé pour la mise en
                                pression des réseaux de plomberie.
                            </p>
                            <p>
                                Une baisse de pression dans le réseau signale un défaut sur
                                l'installation. Un détecteur de gaz permet de repérer avec précision
                                les sortie de gaz
                            </p>
                        </div>

                        <div class="align-self-end-xl align-self-center-sm border-rounded width-400-xl width-300-sm mt-20-sm">
                            <h3 class="text-underline">Inspection vidéo</h3>
                            <p>
                                L'inspection vidéo a pour objectif de vérifier le fonctionnement et
                                les défauts des réseaux d'assainissement sur une longueur de 40
                                mètres.
                            </p>
                            <p>
                                Exemple: fissures, effondrement, dégradation, obstacles, dépots,
                                décentrages, déboitement
                            </p>
                        </div>

                        <div class="align-self-start-xl align-self-center-sm border-rounded width-400-xl width-300-sm mt-20-sm">
                            <h3 class="text-underline">Inspection caméra endoscopique</h3>
                            <p>
                                La caméra endoscopique est un appareil très léger et maniable qui
                                nous permet d'accéder à des endroits très étroits et impossible
                                d'accès tels que:
                            </p>
                            <div style="width: 70%; margin: auto; text-align: left">
                                <p>- sous un bac à douche</p>
                                <p>- derrière les meubles de cuisine aménagée</p>
                                <p>- dans un faux plafond</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeakSearch"
}
</script>

<style scoped>
    .border-rounded {
        background-color: white;
        color: #2c3e50;
        border: 1px solid white;
        border-radius: 15px;
        /*    font-weight: 800;*/
        padding: 4px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
</style>