import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "Accueil | DS SANITEK | Plomberie et dépannage"
    }
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: "/nous-contacter",
    name: "cookies",
    component: () => import('../components/ContactUs.vue'),
    meta: {
      title: "Nous contacter | DS SANITEK | Plomberie et dépannage"
    }
  },
  {
    path: "/mentions-legales/",
    name: "mentions-legales",
    component: () => import('../components/Disclaimer.vue'),
    meta: {
      title: "Mentions légales | DS SANITEK | Plomberie et dépannage"
    }
  },
  {
    path: "*",
    name: "not-found",
    component: () => import('../components/NotFound.vue'),
    meta: {
      title: "404 - Page introuvable"
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.find(r => r.meta && r.meta.title);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }

  next();
})

export default router
