<template>
    <div>
        <svg viewBox="0 0 1024 100">
            <rect x="0" y="0" width="1024" height="100" fill="#FFFFFF"></rect><path d="M0 25L17 28.5C34 32 68 39 102.2 44.5C136.3 50 170.7 54 204.8 56.3C239 58.7 273 59.3 307.2 53.5C341.3 47.7 375.7 35.3 409.8 29C444 22.7 478 22.3 512 27.2C546 32 580 42 614.2 48.3C648.3 54.7 682.7 57.3 716.8 54.5C751 51.7 785 43.3 819.2 38C853.3 32.7 887.7 30.3 921.8 32.8C956 35.3 990 42.7 1007 46.3L1024 50L1024 0L1007 0C990 0 956 0 921.8 0C887.7 0 853.3 0 819.2 0C785 0 751 0 716.8 0C682.7 0 648.3 0 614.2 0C580 0 546 0 512 0C478 0 444 0 409.8 0C375.7 0 341.3 0 307.2 0C273 0 239 0 204.8 0C170.7 0 136.3 0 102.2 0C68 0 34 0 17 0L0 0Z" fill="#1A3852" stroke-linecap="round" stroke-linejoin="miter"></path>
        </svg>
        <div style="padding-bottom: 30px" class="price-list section-holder">
            <section>
                <div class="text-center">
                    <h1 class="text-uppercase" style="margin: auto;">
                        Dépannages et Forfait
                    </h1>
                    <div class="thin-border-rounded width-500-xl p-5" style="margin: 10px auto auto">
                        <p>
                            Les tarifs sont évalués sur un forfait comprenant un déplacement, les fournitures et la main d'œuvre.
                        </p>
                        <p>
                            <router-link to="nous-contacter">Contactez-nous</router-link> pour un devis gratuit <a @click="triggerConditionPopup">sous conditions</a>.
                        </p>

                        <div id="popup" class="overlay" v-if="isConditionPopupEnable">
                            <div class="popup">
                                <a @click="triggerConditionPopup" style="position: absolute; top: 10px; right: 10px">x</a>
                                <div class="content">
                                    <p>
                                        Pour tout déplacement en île-de-France un règlement de 39€ vous
                                        sera demandé pour l'analyse et la réalisation du devis.
                                    </p>
                                    <p>Cette somme vous sera remise si notre devis est accepté</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; flex-wrap: wrap; margin-top: 50px; gap: 20px 20px">
                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/plomberie.png">
                        <p>Réparation fuite sur joint tuyauterie / robinetterie / evacuation.</p>
                    </div>

                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/equipement.png">
                        <p>Debouchage WC</p>
                    </div>

                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/outils.png">
                        <p>Remplacement d'un groupe de sécurité</p>
                    </div>

                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/chaudiere.png">
                        <p>Remplacement chauffe eau électrique</p>
                    </div>

                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/debouchage_evier.png">
                        <p>Debouchage evier/lavabo/ douche/baignoire</p>
                    </div>

                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/flotteur.png">
                        <p>Remplacement flotteur WC standard</p>
                    </div>

                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/wc.png">
                        <p>Remplacement de mécanisme WC complet</p>
                    </div>

                    <div class="thin-border-flexbox">
                        <img style="height: 100px" src="@/assets/camera.png">
                        <p>Recherche de fuite à l'aide de caméra</p>
                    </div>
                </div>

                <div style="margin-top: 30px;">
                    <h2 style="margin: auto;">
                        Forfait dépannage à partir de <span class="price-ttc">120€ HT</span>
                    </h2>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "PriceList",
    data() {
        return {
            isConditionPopupEnable: false,
        };
    },
    methods: {
        triggerConditionPopup() {
            this.isConditionPopupEnable = !this.isConditionPopupEnable;
            if (this.isConditionPopupEnable) {
                document.body.classList.add("body-no-scroll")
            } else {
                document.body.classList.remove("body-no-scroll")
            }
        },
    },
}
</script>

<style scoped>
    .price-list {
        /*background-color: #89B9C5;*/
        background-color: #fff;
    }

    @media only screen and (min-width: 1024px) {
        .width-500-xl {
            width: 500px;
        }
    }

</style>